var _hmt = _hmt || [];

(function () {
  if (location.host === 'www.cheqianqiu.net') {
    // 百度统计
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?6d2e5bea586d57246c1c8ccf186a5ebb";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);

    // 百度推送
    var bp = document.createElement('script');
    var curProtocol = window.location.protocol.split(':')[0];
    if (curProtocol === 'https') {
      bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';
    } else {
      bp.src = 'http://push.zhanzhang.baidu.com/push.js';
    }
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(bp, s);
  }

  // 返回顶部
  const backTop = document.getElementById('back-top')

  let timer = null;
  if (backTop) {
    backTop.onclick = function () {
      cancelAnimationFrame(timer);
      //获取当前毫秒数
      let startTime = +new Date();
      //获取当前页面的滚动高度
      let b = document.body.scrollTop || document.documentElement.scrollTop;
      let d = 500;
      let c = b;
      timer = requestAnimationFrame(function func() {
        let t = d - Math.max(0, startTime - (+new Date()) + d);
        document.documentElement.scrollTop = document.body.scrollTop = t * (-c) / d + b;
        timer = requestAnimationFrame(func);
        if (t >= d) {
          cancelAnimationFrame(timer);
        }
      });
    }
  }

  // 禁止复制
  document.oncopy = e => {
    if (e.target.nodeName === 'TEXTAREA') {
      return true;
    }
    e.clipboardData.setData('text', '请尊重原创，反对抄袭！')
    return false
  }
})();